<template>
  <div class="error_page">
    <div class="container">
      <img src="@/assets/images/error-404.svg" alt="Error Image" />
      <h1 class="error_heading">404 NOT FOUND</h1>
      <p>This page is not available. Check your URL and retry.</p>
      <router-link :to="{ name: 'Home' }" class="cm-btn cm_bg text-white"
        ><i class="fa fa-home mr-1"></i>Home Page</router-link
      >
    </div>
  </div>
</template>

<script>
import "./styles.css";
export default {};
</script>
